<template>
  <div class="section">
    <!--탭메뉴 s-->
    <tab-menu/>
    <!--탭메뉴 e-->

    <p class="tab_exp">관심 콘텐츠에서는 선생님께서 관심 콘텐츠 북마크를 해 놓으신 콘텐츠 내역을 조회하실 수 있습니다.</p>

    <!--내용 영역 s-->

    <!--    <div class="update_area2">-->
    <!--      <span>Last Update</span>2021.04.01-->
    <!--    </div>-->

    <!--리스트 영역 s-->
    <div class="b_lists" v-if="list.length !==0">
      <card-and-list
          @getMoreList="getMoreList"
          @reload="selectParam"
          :is-external-link="true"
          :is-interest-content="true"
      />
    </div>
    <div class="b_lists" v-else>
      <!--리스트 없을 때-->
      <div class="nolist">
        <img src="@/assets/images/icon_alert.png" alt="">
        <p>등록하신 관심 콘텐츠가 없습니다.</p>
      </div>
    </div>
    <!--리스트 영역 e-->

    <!--내용 영역 e-->
  </div>
</template>

<script>
import TabMenu from "@/components/MyMenu/TabMenu";
import CardAndList from "@/components/common/CardAndList";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InterestContent",
  components: {
    TabMenu,
    CardAndList
  },
  async mounted() {
    await this.initInterestContentList();
  },
  computed: {
    ...mapGetters({
      page : 'getInterestContentPage',
      list : 'getInterestContentList',
    })
  },
  methods: {
    ...mapActions(['initInterestContentList', 'fetchInterestContentList']),
    async selectParam() {
      await this.initInterestContentList();
    },
    async getMoreList() {
      await this.fetchInterestContentList({
        query : {
          page : this.page + 1
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
