<template>
  <!--탭메뉴 s-->
  <div class="lsheading">
    <div class="lsheading-content">
      <div class="pod-actions lsheading-action">
        <div class="lsheading-nav">
          <template v-for="(menu, index, number) in menuList">
            <a
                v-if="menu.external"
                :key="index"
                :href="menu.href"
                :title="menu.menuName"
                :aria-label="menu.menuName"
                target=""
                active-class="active"
                class="lsanchor"
                v-text="menu.menuName"></a>
            <router-link
                v-else
                :key="index"
                :to="{name: menu.name, params: {id: menu.menuName}}"
                :title="menu.menuName"
                :aria-label="menu.menuName"
                target=""
                active-class="active"
                class="lsanchor"
                v-text="menu.menuName"/>
            <div v-if="number < Object.keys(menuList).length - 1 " class="ruler" :key="`s_${index}`"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!--탭메뉴 e-->
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "TabMenu",
  computed: {
    ...mapGetters({menuList: 'getMyPageMenuList'})
  },
}
</script>

<style scoped>

</style>
