<template>
  <div class="section">

    <!--Page Title 영역 s-->
    <!-- 상위 템플릿 추가, InterestContent.vue에서 헤더 부분 제거를 위해 만들었습니다. 기본값은 true입니다   -->
    <template>
      <div class="tit_page_area">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
        <div class="select_area" v-if="isTaSelectable">
          <select name="sch_arear" class="sel_cal" v-model="selectTherapeuticArea" @change="$emit('reload',{
              ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
            })">
            <option value="">TherapeuticArea</option>
            <option v-for="(menu, index) in therapeuticArea" :key="index"
                    :value="menu.value" v-html="menu.name"/>
          </select>
          <select name="sch_prod" class="sel_cal" v-model="selectProduct" @change="$emit('reload',{
              ...selectTherapeuticArea && {'ta': selectTherapeuticArea},
              ...selectProduct && {'product': selectProduct},
            })">
            <option value="">Product</option>
            <option v-for="(product, index) in productList[selectTherapeuticArea]" :key="index"
                    :value="product.value" v-html="product.name"/>
          </select>
        </div>
        <div class="tit_right_icon_area" v-if="!isFixedListMode">
          <a @click="isCardView = !isCardView" role="link">
            <img v-if="isCardView" src="@/assets/images/icon_cardview.png" alt="">
            <img v-else src="@/assets/images/icon_listview.png" alt="">
          </a>
        </div>
      </div>
      <template v-if="!isWebinarVod">
      <div class="update_area" v-if="lastUpdatedAt">
        <span>Last Update</span>{{ lastUpdatedAt }}
      </div>
      </template>
      <template v-else>
      <div class="update_area" v-if="webinarVodLastUpdatedAt">
        <span>Last Update</span>{{ webinarVodLastUpdatedAt }}
      </div>
      </template>
    </template>
    <!--Page Title 영역 e-->
    <template v-if="isWebinarVod">
      <!--리스트 영역 s-->
      <div :class="['b_lists', {'b_lists_card':isCardView}]" v-if="webinarVodList.length !==0">
        <list-item
            v-for="(item,index) in webinarVodList"
            :key="index"
            :item="item"
            :mode="isCardView"
            :is-external-link="isExternalLink"/>
        <!--more버튼-->
      </div>
      <div class="btn_area btn_more" v-if="!webinarVodIsLast">
        <button @click="moreList">Load More</button>
      </div>

      <!--리스트 영역 e-->

      <!--리스트 영역 s-->
      <div class="b_lists" v-if="webinarVodIsLast.length ===0">
        <!--리스트 없을 때-->
        <div class="nolist">
          <img src="@/assets/images/icon_alert.png" alt="">
          <p>컨텐츠 <span>준비중</span>입니다.</p>
          <p>이용에 불편을 드려서 죄송합니다.<br>
            빠른 시일내에 컨텐츠를 준비하도록 하겠습니다.</p>
        </div>
      </div>
    </template>
    <template v-else-if="isInterestContent">
    <!--리스트 영역 s-->
      <div :class="['b_lists', {'b_lists_card':isCardView}]" v-if="interestContentList.length !==0">
        <list-item
            v-for="(item,index) in interestContentList"
            :key="index"
            :item="item"
            :mode="isCardView"
            :is-external-link="isExternalLink"/>
        <!--more버튼-->
      </div>
      <div class="btn_area btn_more" v-if="!interestContentIsLast">
        <button @click="moreList">Load More</button>
      </div>

    <!--리스트 영역 e-->

    <!--리스트 영역 s-->
    <div class="b_lists" v-if="interestContentList.length ===0">
      <!--리스트 없을 때-->
      <div class="nolist">
        <img src="@/assets/images/icon_alert.png" alt="">
        <p>등록하신 관심 콘텐츠가 없습니다.</p>
      </div>
    </div>
    <!--리스트 영역 e-->
    </template>
    <template v-else>

      <!--리스트 영역 s-->
      <div :class="['b_lists', {'b_lists_card':isCardView}]" v-if="boardList.length !==0">
        <list-item
            v-for="(item,index) in boardList"
            :key="index"
            :item="item"
            :mode="isCardView"
            :is-external-link="isExternalLink"/>
        <!--more버튼-->
      </div>
      <div class="btn_area btn_more" v-if="!isLast">
        <button @click="moreList">Load More</button>
      </div>

      <!--리스트 영역 e-->

      <!--리스트 영역 s-->
      <div class="b_lists" v-if="boardList.length ===0">
        <!--리스트 없을 때-->
        <div class="nolist">
          <img src="@/assets/images/icon_alert.png" alt="">
          <p>컨텐츠 <span>준비중</span>입니다.</p>
          <p>이용에 불편을 드려서 죄송합니다.<br>
            빠른 시일내에 컨텐츠를 준비하도록 하겠습니다.</p>
        </div>
      </div>
      <!--리스트 영역 e-->
    </template>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem";
import {mapGetters} from "vuex";

export default {
  name: "CardAndList",
  components: {ListItem},
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    isFixedListMode: {
      type: Boolean,
      default: false,
    },
    isSelectedView: {
      type: Boolean,
      default: false,
    },
    isExternalLink: {
      default: false,
    },
    isTaSelectable: {
      default: false,
    },
    isWebinarVod: {
      default: false,
    },
    isInterestContent: {
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      boardList: "getBoardList",
      isLast: "isLast",
      lastUpdatedAt: "getLastUpdatedAt",
      webinarVodList: "getWebinarVODList",
      webinarVodIsLast: "getWebinarVODIsLast",
      webinarVodLastUpdatedAt: "getWebinarVODLastUpdatedAt",
      interestContentList: "getInterestContentList",
      interestContentIsLast: "getInterestContentIsLast",
      interestContentLastUpdatedAt: "getInterestContentLastUpdatedAt",
    }),
  },
  watch: {
    selectTherapeuticArea() {
      this.selectProduct = ''
    }
  },
  data() {
    return {
      isCardView: this.isSelectedView ?? false,
      selectTherapeuticArea: '',
      selectProduct: '',
      therapeuticArea: [
        {
          name: 'Hematology',
          value: 1
        },
        {
          name: 'Oncology',
          value: 2
        },
        {
          name: 'Immunology',
          value: 3
        },
        {
          name: 'Virology',
          value: 4
        },
        {
          name: 'Cardiology',
          value: 5
        },
      ],
      productList: {
        1: [
          {
            name: 'SPRYCEL<sup>®</sup> <span>(dasatinib)</span>',
            value: 'sprycel'
          },
          {
            name: 'VIDAZA<sup>®</sup> <span>(azacitidine)</span>',
            value: 'vidaza'
          },
          {
            name: 'REVLIMID<sup>®</sup> <span>(lenalidomide)</span> / POMALYST<sup>®</sup> <span>(pomalidomide)</span>',
            value: 'revlimid-pomalyst'
          },
        ],
        2: [
          {
            name: 'OPDIVO<sup>®</sup> <span>(nivolumab)</span> / YERVOY<sup>®</sup> <span>(ipilimumab)</span>',
            value: 'opdivo-yervoy',
          },
          {
            name: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
            value: 'abraxane',
          },
        ],
        3: [
          {
            name: 'ORENCIA<sup>®</sup> <span>(abatacept)</span>',
            value: 'orencia',
          },
        ],
        4: [
          {
            name: 'BARACLUDE<sup>®</sup> <span>(entecavir)</span>',
            value: 'Virology'
          }
        ],
        5: [
          {
            name: 'ELIQUIS<sup>®</sup> <span>(apixaban)</span>',
            value: 'eliquis',
          },
        ],
      },
    }
  },
  methods: {
    moreList() {
      this.$emit('getMoreList')
    }
  },
}
</script>

<style scoped>

</style>
