<template>
  <router-link :to="item.link" :append="!isExternalLink"
               v-slot="{ href, route, navigate }"
               custom>
    <div :class="[mode ? 'b_card' : 'listview']" @click="navigate" role="link">
      <h1 v-html="$options.filters.supTag(item.title)"/>
      <p class="article" v-html="$options.filters.supTag(item.content)" v-if="item.content"/>
      <div class="list_date">
        <span v-if="item.date">Date</span> {{ item.date }}
        <span v-if="item.view || item.view >= 0 ">Views</span> {{ item.view }}
      </div>
      <img :src="item.thumbnail" alt=""/>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: Boolean,
      default: false,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    }
  },
  filters: {
    supTag: function (value) {
      return (value || '').replace(/[®™]/gi, '<sup>$&</sup>');
    }
  },
}
</script>

<style scoped lang="scss">
.b_card {
  img {
    order: 1;
  }

  h1 {
    order: 2;
  }

  p {
    order: 3;
  }

  div {
    order: 4
  }
}
</style>
